import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import { render } from 'react-dom';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
// import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'production') {
    import('@sentry/react').then((sentry) => {
        sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [
                sentry.browserTracingIntegration(),
                sentry.replayIntegration({
                    maskAllText: false,
                    maskAllInputs: false,
                    blockAllMedia: false,
                    networkDetailAllowUrls: process.env.REACT_APP_API_SERVICE_ENDPOINT
                        ? [process.env.REACT_APP_API_SERVICE_ENDPOINT]
                        : [],
                    unmask: ['.sentry-unmask', '[data-sentry-unmask]'],
                }),
            ],
            // Performance Monitoring
            tracesSampleRate: 0.25, // Capture 25% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.05, // This sets the sample rate at 5%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            tracePropagationTargets: process.env.REACT_APP_API_SERVICE_ENDPOINT
                ? [process.env.REACT_APP_API_SERVICE_ENDPOINT]
                : undefined,
        });
    });
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000, // 5min
        },
    },
});

render(
    <StrictMode>
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </ErrorBoundary>
    </StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
