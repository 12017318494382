import { getOrCreateUUIDCookie } from '@keyliving/utils';

/**
 * Return data in a consistent format wether text or JSON response
 */
async function parseResponse(response: Response) {
    const contentType = response.headers.get('content-type');

    if (contentType && contentType.startsWith('text/plain')) {
        const data = await response.text();

        return { data };
    }

    const data = await response.json();

    return data;
}

interface customFetchParams {
    body?: Record<string, any>;
    method: string;
    token?: string;
    url: string;
}

/**
 * Base fetch handler that will automatically add an auth token if one
 * exists in state, sets the Content-Type for us and handles our
 * custom error responses.
 */
export async function customFetch<T>({
    body,
    method = 'GET',
    token,
    url,
}: customFetchParams): Promise<T> {
    const xKeyAnonId = getOrCreateUUIDCookie();
    const headers = new Headers({
        'Content-Type': 'application/json',
    });

    if (xKeyAnonId) {
        headers.append('x-key-anon-id', xKeyAnonId);
    }

    /**
     * Would love to be able to get the auth token off the cookie here but
     * can't use a hook (e.g. useGlobalAuth) in this context
     */
    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
    }

    try {
        const response = await fetch(url, {
            method,
            headers,
            body: JSON.stringify(body),
        });

        const data = await parseResponse(response);

        /**
         * The api responds with application/json in the form of { err: true }
         * when there is an error so we need to check for an error response
         * as well as response.ok to be safe...
         *
         * "ok" is a status in the range 200-299
         *
         * Our servers in environments other than local dev, strip the
         * statusText. We also don't return an error message unless
         * surfaceToClient is explicitly set to true so we might
         * not have a helpful message.
         */
        if (!response.ok || 'err' in data) {
            return Promise.reject({
                status: response.status,
                message: data?.message ?? 'Something went wrong',
            });
        }

        return data;
    } catch (error) {
        return Promise.reject({
            status: 500,
            message: 'Network Error',
        });
    }
}
