import { booleanOrNull } from '@keyliving/utils';
import { boolean, object, preprocess, string } from 'zod';

export default function useEnv() {
    const envSchema = object({
        REACT_APP_API_SERVICE_ENDPOINT: string().min(1),
        REACT_APP_IDENTITY_SERVICE_ENDPOINT: string().min(1),
        REACT_APP_MOCK_DATA: preprocess(booleanOrNull, boolean()),
        REACT_APP_SENTRY_DSN: string().optional(),
        REACT_APP_GCP_API_KEY: string().min(1),
    });

    return envSchema.parse(process.env);
}
