import type { Claims } from '@keyliving/shared-types';
import { useMutation } from '@tanstack/react-query';

import { customFetch } from '../lib/customFetch';
import useEnv from './useEnv';

export default function useVerifyAuthTokenMutation() {
    const { REACT_APP_IDENTITY_SERVICE_ENDPOINT } = useEnv();

    return useMutation({
        mutationFn: async (token: string) => {
            const res = await customFetch<{ data: { claims: Claims } }>({
                url: `${REACT_APP_IDENTITY_SERVICE_ENDPOINT}/users/verify`,
                method: 'POST',
                body: { token },
            });

            return res.data.claims;
        },
    });
}
