import '@keyliving/component-lib/dist/css/global.css';
import './scss/app.scss';

import { lazy, Suspense } from 'react';

import HydrationGate from './components/HydrationGate';
import FullScreenFallbackLayout from './components/layout/FullScreenFallbackLayout';

const Home = lazy(() => import('./pages/Home'));

export default function App() {
    return (
        <HydrationGate>
            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                <Home />
            </Suspense>
        </HydrationGate>
    );
}
