import { useGlobalAuth } from '@keyliving/component-lib';
import type { User } from '@keyliving/shared-types';
import { isTokenExpired } from '@keyliving/utils';
import { getCurrentScope } from '@sentry/react';
import type { ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';

import useEnv from '../hooks/useEnv';
import useVerifyAuthTokenMutation from '../hooks/useVerifyAuthTokenMutation';
import { customFetch } from '../lib/customFetch';
import FullScreenFallbackLayout from './layout/FullScreenFallbackLayout';

interface HydrationGateProps {
    children: ReactElement | null;
}

export default function HydrationGate({ children }: HydrationGateProps) {
    const [canMount, setCanMount] = useState<boolean>(false);
    const { token: authToken } = useGlobalAuth();
    const { mutateAsync: verifyToken } = useVerifyAuthTokenMutation();
    const { REACT_APP_IDENTITY_SERVICE_ENDPOINT } = useEnv();

    const hydrateCurrentUser = useCallback(async () => {
        try {
            const res = await customFetch<{ user: User }>({
                url: `${REACT_APP_IDENTITY_SERVICE_ENDPOINT}/users/me`,
                method: 'GET',
                token: authToken,
            });

            /**
             * When we set a user, identify that user across our
             * various tracking services.
             */
            const { id, org_codes } = res.user;

            let userOrg: string | null = null;

            if (org_codes && org_codes.length) {
                const [firstOrg] = org_codes;
                userOrg = firstOrg;
            }

            /**
             * Sentry - setup a scope for Sentry that will
             * add details to captured exceptions for that user.
             */
            const scope = getCurrentScope();
            scope.setUser({ id });

            if (userOrg) {
                scope.setTag('org', userOrg);
            }

            // Google Analytics
            window.dataLayer.push({
                user_id: id,
                org: userOrg,
            });

            return Promise.resolve();
        } catch (error) {
            /**
             * Just swallow the error for now. When the app mounts it will
             * redirect to the /login route without a user
             */
            return Promise.reject();
        }
    }, [REACT_APP_IDENTITY_SERVICE_ENDPOINT, authToken]);

    const hydrateApp = useCallback(() => {
        Promise.allSettled([hydrateCurrentUser()]).finally(() => {
            setCanMount(true);
        });
    }, [hydrateCurrentUser]);

    const initializeApp = useCallback(async () => {
        // If we don't have an authToken, we aren't logged in...
        if (!authToken) {
            setCanMount(true);
            return;
        }

        try {
            // Verify the auth token...
            const authUserClaims = await verifyToken(authToken);

            // ...token expiry should be handled by the api, but to be safe...
            const isExpired = isTokenExpired(authUserClaims.exp);

            if (isExpired) {
                throw new Error('Token expired');
            }

            hydrateApp();
        } catch (error) {
            setCanMount(true);
        }
    }, [authToken, verifyToken, hydrateApp]);

    useEffect(() => {
        initializeApp();

        // Only once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!canMount) {
        return <FullScreenFallbackLayout isLoading />;
    }

    return children;
}
