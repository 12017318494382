/**
 * Checks if the input value is a string and compares to our common use case
 * of using yes/no, true/false and either returns a boolean or null in
 * the case of an empty string.
 */
export default function booleanOrNull(val: unknown): boolean | null | unknown {
    if (typeof val === 'string') {
        if (val.length === 0) {
            return null;
        }

        if (val.toLowerCase() === 'yes' || val.toLowerCase() === 'true') {
            return true;
        }

        if (val.toLowerCase() === 'no' || val.toLowerCase() === 'false') {
            return false;
        }
    }

    return val;
}
